
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.take-it-out-section {
  position: relative;
  margin-bottom: 30px;

  .heroText {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    color: color.$white-color;
    top: 42%;

    @media screen and (min-width: 575px) {
      top: 46%;
    }

    @media screen and (min-width: 992px) {
      color: color.$white-color;
      padding-left: 30px;
      padding-right: 30px;
      top: 55%;
    }

    @media screen and (min-width: 1500px) {
      top: 60%;
    }

    .section-heading {
      font-size: 34px;
      line-height: 36px;
      margin-bottom: 0px;
      text-shadow: 1px 1px 30px color.$black3;
      letter-spacing: -0.015em;
      font-weight: typography.$bold;
      color: color.$white-color;

      @media screen and (min-width: 768px) {
        font-size: 30px;
        line-height: 34px;
      }
    }

    p {
      display: block;
      font-size: 18px;
      line-height: 24px;
      text-shadow: 1px 1px 30px color.$black3;
      max-width: 300px;
      margin: 0 auto 15px;

      @media screen and (min-width: 768px) {
        font-size: 16px;
        line-height: 20px;
        max-width: 250px;
      }

      @media screen and (min-width: 992px) {
        font-size: 24px;
        line-height: 26px;
        max-width: 300px;
      }
    }
  }

  .heroBtns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 15px;
    background: color.$blue;
    flex-direction: column;
    padding: 30px 15px 15px;

    @media screen and (min-width: 768px) {
      padding: 15px;
      flex-direction: row;
      gap: 20px;
    }

    @media screen and (min-width: 992px) {
      top: 86%;
      left: 0;
      position: absolute;
      z-index: 1;
      flex-direction: row;
      background: none;
    }
  }

  .section-heading {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 0px;
    text-shadow: 1px 1px 30px color.$black3;
    letter-spacing: -0.015em;
    font-weight: typography.$bold;
    color: color.$white-color;
  }

  p {
    display: block;
    font-size: 24px;
    line-height: 26px;
    text-shadow: 1px 1px 30px color.$black3;
    max-width: 300px;
    margin: 0 auto 15px;
  }
}
