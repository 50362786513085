
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.eyebrow-carousel {
  display: inline-block;
  .eyebrow-carousel-items {
    padding: 0 30px;
    width: auto;
  }
  button {
    width: 20px;
  }
}

.headerEye {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: color.$blue;
  letter-spacing: -0.4px;
  padding: 0px;
  font-weight: typography.$medium;
  @media screen and (max-width: 767px) {
    height: 46px;
    min-height: 46px;
  }
  @media screen and (min-width: 992px) {
    .headerEye {
      min-height: 26px;
      margin-top: 3px;
    }
  }
  p {
    font-size: 11px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: color.$blue;
    padding: 3px 15px;
    margin: 0;

    @include breakpoint('medium') {
      font-size: 12px;
      line-height: 16px;
      padding: 0;
      width: 480px;
    }
    @include breakpoint('large') {
      font-size: 14px;
      width: 580px;
    }
    @include breakpoint('xlarge') {
      width: auto;
    }
    br {
      display: none;
    }
    a {
      white-space: nowrap;
    }
  }
  a {
    color: color.$blue;
    text-decoration: underline;
  }
}
