
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.ulClass {
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (min-width: 768px) {
    display: inline-block;
    vertical-align: top;
    padding: 0;
  }
  li {
    margin: 0 0 8px;
    list-style: none;
    line-height: 18px;
    padding-left: 10px;
    color: color.$black-color;
    @media screen and (min-width: 768px) {
      padding-left: 0;
    }
    a {
      padding-bottom: 10px;
      color: color.$black-color;
      display: block;
      &:hover {
        text-decoration: underline;
      }
      @media screen and (min-width: 768px) {
        padding-bottom: 0;
      }
      @media screen and (min-width: 992px) {
        letter-spacing: -0.21px;
      }
      &:empty {
        display: none;
      }
    }
  }
}
