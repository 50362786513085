
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.certificateImage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
