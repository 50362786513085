
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.tabContainer {
  display: flex;
  gap: 3px;
  border-bottom: 1px solid color.$gray-gainsboro;
  margin-top: 19px;
  @include breakpoint('xlarge') {
    gap: 5px;
  }
  li {
    background-color: color.$gray-f0f0f1;
    border: 1px solid color.$light-gray;
    padding: 10px 3px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-weight: typography.$light;
    font-size: 12px;
    @include breakpoint('xlarge') {
      padding: 10px 15px;
      font-size: 14px;
    }
    a {
      color: #333333;
    }
    &.active {
      color: color.$black;
      background-color: color.$white-color;
      border-bottom: 2px solid color.$red;
      font-weight: typography.$bold;
    }
  }
  .tabItem {
    text-decoration: none;
    color: color.$black3;
    &:hover {
      text-decoration: underline;
    }
  }
}
