
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.plp-container {
  margin: 0px auto;
  padding: 20px 15px;
  @include breakpoint('large') {
    padding: 0 27px;
  }
}

.plp-container-box {
  display: flex;
}

.product-guided-nav {
  display: none;
  @include breakpoint('large') {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
}

.plp-seoData {
  font-weight: typography.$light;
  display: none;
  @include breakpoint('small') {
    display: block;
  }
  @include breakpoint('medium') {
    padding: 15px 0 30px;
  }
  @include breakpoint('xxlarge') {
    margin-bottom: 40px;
    padding: 0px;
  }
}

.product-list {
  margin: 0 auto;
  position: relative;
}

.gift-card-banner {
  position: relative;
  min-height: 70px;
  padding: 0 15px;
  @include breakpoint('large') {
    margin-bottom: 60px;
    margin-top: -15px;
  }
  .gift-card-btn {
    position: absolute;
    bottom: 37px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    padding: 8px 10px;
    width: auto;
    line-height: 16px;
    font-weight: typography.$medium;
    transition: all 0.1s ease-in-out;
    min-width: 165px;
    letter-spacing: -0.015em;
    &:hover {
      transform: translateX(-50%);
    }
    @media screen and (min-width: 376px) {
      bottom: 45px;
    }

    @include breakpoint('large') {
      bottom: 10px;
      left: 1%;
      transform: unset;
      &:hover {
        transform: scale(1.04, 1.04);
      }
    }
    @include breakpoint('mlarge') {
      bottom: 10px;
      left: 1.7%;
      transform: unset;
      &:hover {
        transform: scale(1.04, 1.04);
      }
    }
    @include breakpoint('xlarge') {
      bottom: 15px;
      left: 2.3%;
    }
    @include breakpoint('xxlarge') {
      left: 4.5%;
    }
  }
}

.product-layout {
  flex: 1;
}
.store-result-list {
  padding-top: 50px;
  position: relative;
  @include breakpoint('large') {
    padding-top: 0;
    position: static;
  }
}

.result-title {
  font-size: 25px;
  font-weight: typography.$bold;
}

.srp-container {
  margin: 0px auto;
  padding: 0px 15px 0px;
  @include breakpoint('medium') {
    max-width: 750px;
  }
  @include breakpoint('large') {
    padding: 0 27px;
    max-width: 1170px;
  }
}

.srp-container-box {
  margin-top: 40px;
  font-weight: typography.$bold;
  h1 {
    margin: 0;
    font-weight: typography.$bold;
    font-size: 25px;
    @include breakpoint('medium') {
      margin-bottom: 8px;
    }
  }
}

.srp-categories {
  margin: 0px -12px;
  @include breakpoint('large') {
    max-width: 1170px;
  }
}

.new-trending-wrapper {
  padding: 0 25px;
  @include breakpoint('medium') {
    padding: 0;
  }
}
.new-trending-title {
  text-align: left;
  margin: 20px 0 30px;
  @include breakpoint('medium') {
    text-align: center;
    margin: 20px 0 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .trending-items-wrap {
    margin: 0 60px;
  }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
  .trending-items-wrap {
    margin-left: 25px;
  }
}

.carousel-items-no-result {
  margin-bottom: 10px;
  img {
    max-width: 100%;
  }
  .no-result-reg-price {
    text-transform: lowercase;
    font-size: 0.86em;
  }
  .no-result-item-image {
    display: inline-block;
    min-height: 7rem;
    @media screen and (min-width: 375px) {
      min-height: 8.5rem;
    }
  }
}
