
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.legal {
  text-align: center;
  padding: 10px 0;
  background: color.$gray3;
  font-size: 11px;
  background: color.$gray3;

  @media screen and (min-width: 1200px) {
    text-align: left;
  }
  ul {
    li {
      list-style: none;
    }
    display: flex;
    gap: 10px;
    margin: -14px 0 0;
    @media screen and (max-width: 767px) {
      justify-content: center;
    }
    @media screen and (max-width: 991px) {
      margin: -14px 0 10px;
      justify-content: center;
    }
  }

  .footerLinkWrapper {
    padding-top: 30px;
    border-top: 1px solid color.$light-gray;
    @media screen and (min-width: 992px) {
      margin-left: 0;
      margin-right: 0;
    }
    @media screen and (min-width: 1200px) {
      align-items: center;
      padding-top: 30px;
    }
    > div {
      @media screen and (min-width: 992px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .footerpromo {
    font-size: 11px;
    color: color.$gray2;
    font-weight: typography.$light;
    margin-bottom: 12px;
    a {
      border-bottom: 1px solid color.$thin-gray;
      color: color.$gray2;
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 2px;
    }
  }

  .footer-policy {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    letter-spacing: -0.015em;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      display: inline-block;
      letter-spacing: -0.022em;
      > div {
        display: inline-block;
      }
    }
    @media screen and (min-width: 1200px) {
      display: flex;
      justify-content: flex-end;
      flex-wrap: nowrap;
      flex-direction: row;
      padding-bottom: 12px;
    }
    a {
      color: color.$black-color;
      &:hover {
        text-decoration: underline;
      }
    }
    .link-divider {
      margin: 0 7px;
      color: color.$black-color;
    }
    .hidden-xs {
      display: none;
      @media screen and (min-width: 768px) {
        display: inline-block;
      }
    }
  }

  .copy-right {
    font-size: 11px;
    font-weight: typography.$light;
    margin-bottom: 3px;
    border-top: 1px solid color.$light-gray;
    padding-top: 10px;
    margin-top: 10px;
    color: color.$black-color;
    @media (min-width: 1200px) {
      margin-top: 16px;
      text-align: center;
    }
  }

  .container {
    padding: 0 15px;
    margin: 0 auto;
    @media (min-width: 768px) {
      width: 100%;
    }
    @media (min-width: 992px) {
      width: 970px;
    }
    @media (min-width: 1200px) {
      width: 1170px;
    }
  }
}
