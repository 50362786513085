
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.textareacontrol {
  label {
    font-size: 0.875rem;
    line-height: 22px;
    letter-spacing: -0.015em;
    color: color.$black-color;
    font-weight: 700;
    span {
      color: color.$black-color;
      font-size: 0.563rem;
    }
  }

  textarea {
    border-radius: 4px;
    border: 1px solid color.$light-gray;
    padding: 10px 16px;
    font-size: 1.125rem;
    line-height: 1.3333333;
    color: color.$grey10;
    background-color: color.$white-color;
    transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
    resize: none;
    &:focus {
      border-color: color.$light-blue;
      outline: 0;
      box-shadow:
        inset 0 1px 1px color.$black-shadow,
        0 0 8px color.$light-blue-shadow;
    }
  }

  p {
    color: color.$red;
    display: none;
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.textareacontrol.error {
  textarea {
    border-color: color.$red;
    &:focus {
      border-color: color.$red;
      box-shadow:
        inset 0 1px 1px color.$black-shadow,
        0 0 6px color.$red4;
    }
  }
  p {
    display: block;
  }
}
