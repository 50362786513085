
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.storePopover {
  position: absolute;
  background: color.$white-color;
  z-index: 1;
  color: color.$black;
  top: 100%;
  padding: 20px 15px 10px;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  left: 50%;
  transform: translateX(-50%);
  .storePopoverIcon {
    position: absolute;
    display: block;
    border-color: transparent;
    border-style: solid;
    top: -24px;
    left: 45%;
    &::after {
      content: '';
      width: 20px;
      height: 20px;
      display: block;
      border-width: 11px;
      border-color: transparent;
      border-style: solid;
      border-bottom-color: color.$white-color;
      position: absolute;
      top: 0;
    }
  }
}

.header-link-store {
  text-decoration: underline;
}
