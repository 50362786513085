
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.plp-resultList {
  margin-top: 0px;
  @include breakpoint('medium') {
    margin-top: 25px;
  }
}
