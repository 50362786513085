
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.heroImage {
  position: relative;
  margin-bottom: 20px;
  img {
    width: 100%;
    height: auto;
  }
  .btn-wrapper {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    align-content: center;
    bottom: 0;
    left: 0;
    justify-content: center;
    gap: 12px;
    padding: 0 15px;
    margin: 0;
    @media screen and (min-width: 499px) {
      padding-left: 15px;
    }
    @media screen and (min-width: 600px) {
      padding-left: 32px;
      padding-right: 5px;
      justify-content: flex-end;
    }
    @media screen and (min-width: 1024px) {
      top: 50%;
      transform: translateY(-50%);
    }
    @media screen and (min-width: 1200px) {
      padding: 4px 12px;
    }
    @media screen and (min-width: 1300px) {
      padding: 9px 12px;
    }
    @media screen and (min-width: 1500px) {
      padding: 1.5%;
    }
    .save-nav {
      font-size: 11px;
      height: 30px;
      width: 100px;
      margin: 5px 0;
      line-height: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: typography.$bold;
      padding: 7px 24px;
      @media screen and (min-width: 499px) {
        font-size: 0.703rem;
        height: 35px;
        width: 112px;
        padding: 7px 32px;
      }
      @media screen and (min-width: 600px) {
        padding: 11px 15px;
        font-size: 13px;
        height: 50px;
        margin: 0.5% 0.5%;
        width: 100px;
        line-height: normal;
      }
      @media screen and (min-width: 1024px) {
        padding: 5px 8px;
        margin: 0px 0.5%;
        font-size: 12px;
        height: 50px;
        width: 95px;
      }
      @media screen and (min-width: 1200px) {
        margin: 0px 1.5%;
        font-size: 0.875rem;
        height: 48px;
        width: 123px;
      }
      @media screen and (min-width: 1300px) {
        font-size: 0.938rem;
        margin: 0px 5px;
        padding: 6px 11px;
        width: 146px;
        height: 48px;
      }
    }
  }
}
