
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.rating-text {
  color: color.$blue-black;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.18px;
  margin-top: 3px;
  margin-right: 5px;
}
