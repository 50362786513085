
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.coupon-border {
  padding: 20px;
  border: 1px dashed #000;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 550px;
}

.logo {
  margin: 20px 0;
  width: 200px;
}

.coupon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.coupon-text {
  color: #020202;
  margin: 0px 0px 15px;
  padding: 15px 0 0;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.print-button {
  margin-right: 20px;
}

.legal-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 20px;
  max-width: 39em;
}

.legal-text {
  text-align: justify;
  color: #666666;
  margin: 0px 0px 20px;
  padding: 0px 15px;
  margin-bottom: 100px;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.coupon-skeleton {
  margin-top: 50px;
}

.barcode {
  width: 200px;
  margin: auto;
}
.close-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.close-button-customClass {
  background-color: color.$gray2;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
