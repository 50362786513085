
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.breadcrumb {
  padding: 22px 0 19px 0;
  margin: 0;
  background: 0 0;
  font-size: 11px;
  border-radius: 0;
  display: none;
  @include breakpoint('large') {
    display: block;
  }
  .separator {
    &:before {
      width: 0;
      content: '';
      height: 0;
      margin-left: 8px;
      margin-right: 7px;
      overflow: hidden;
      display: inline-block;
      border-left: 4px solid color.$red-crimson;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }

  a {
    color: color.$black-color;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
