
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.cart-li {
  a {
    display: flex;
    flex-direction: column;
    padding: 15px 3px;
    @media screen and (min-width: 376px) {
      padding: 15px 11px 15px 7px;
    }
    @media screen and (min-width: 768px) {
      padding: 0px 11px 0px 2px;
    }
    @media screen and (min-width: 992px) {
      padding: 0px;
    }
  }
  .cart-icon {
    width: 25px;
    height: 25px;
    background: variables.$background-lakeshore-sprites -150px 0 / 250px 250px;
    @media screen and (min-width: 768px) {
      width: 33px;
      height: 30px;
      background: variables.$background-lakeshore-sprites -198px 0 / 330px 330px;
    }
    @media screen and (min-width: 992px) {
      width: 45px;
      height: 45px;
      display: block;
      background: variables.$background-lakeshore-sprites -90px 1px / 450px
        450px;
      &:hover {
        background: variables.$background-lakeshore-sprites -135px 1px / 450px
          450px;
      }
    }
  }
  .text-icon {
    margin-top: 6px;
    color: color.$white-color;
    text-align: center;
    display: none;
    @media screen and (min-width: 992px) {
      display: block;
    }
  }
}

@media all and (min-width: 992px) {
  .rewards-icon {
    background: variables.$background-lakeshore-sprites -135px -225px/450px
      450px;
    width: 45px;
    height: 45px;
    float: left;
    &:hover {
      background: variables.$background-lakeshore-sprites -180px -225px / 450px
        450px;
    }
  }
}
.rewards-li {
  display: none !important;
  @media all and (min-width: 992px) {
    display: block !important;
  }
  .store-icon {
    width: 45px;
    height: 45px;
    background: variables.$background-lakeshore-sprites -135px -225px / 450px
      450px;
    display: block;
  }
  a {
    margin-top: 5px;
    display: inline-block;
    color: color.$white-color;
  }
  @media all and (min-width: 991px) {
    .rewards-icon-link {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      margin-right: 18px;
      white-space: inherit;
      margin-top: 0px;

      .link-wrapper {
        margin-top: 6px;
      }
    }
  }
}

.cart-badge {
  top: 9px;
  position: absolute;
  right: -1px;
  font-size: 9px;
  padding: 3px 4px;
  line-height: 1;
  color: color.$white-color;
  background-color: color.$red-crimson;
  text-align: center;
  white-space: nowrap;
  border-radius: 50%;
  min-width: 15px;
  font-weight: typography.$bold;
  @media all and (min-width: 768px) {
    top: -3px;
    right: -3px;
  }
  @media all and (min-width: 992px) {
    top: 7px;
    right: 0px;
  }
}
// Mobile Right CSS
.account-menu-link_rewards-icon__Q8Sh4 {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  float: left;
  background: variables.$background-lakeshore-sprites 0 -20px / 200px 200px;
}
