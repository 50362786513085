
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.inputField {
  input {
    font-size: 0.875rem;
  }
}
