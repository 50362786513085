
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
// Variations of background

.background-flavor {
  background-color: var(--bgColor);
}

.color-black {
  color: color.$gray2;
}

// Start styling

.rounded_rectangle-section {
  padding: 20px 0px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0;
  gap: 10px;
  @media screen and (min-width: 375px) {
    padding: 10px 15px 0 15px;
    justify-content: space-around;
    gap: 0;
  }
  @media screen and (min-width: 992px) {
    gap: 10px;
    margin-bottom: 30px;
    padding: 20px 10px;
    justify-content: center;
  }
  h2 {
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
    color: color.$white-color;
    width: 100%;
    font-weight: typography.$light;
    @media screen and (min-width: 375px) {
      margin-bottom: 15px;
    }
    @media screen and (min-width: 992px) {
      font-size: 18px;
      margin: 0;
      width: auto;
    }
  }
  .rounded_rectangle-chiclet {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 15px;
    flex-wrap: wrap;
    padding: 0 7px;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      gap: 10px;
      padding: 0;
    }
  }
}

.rectangle-section {
  padding: 20px 0px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0;
  gap: 10px;
  @media screen and (min-width: 375px) {
    padding: 10px 15px 0 15px;
    justify-content: space-around;
    gap: 0;
  }
  @media screen and (min-width: 992px) {
    gap: 10px;
    margin-bottom: 30px;
    padding: 20px 10px;
    justify-content: center;
  }
  h2 {
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
    color: color.$white-color;
    width: 100%;
    font-weight: typography.$light;
    @media screen and (min-width: 375px) {
      margin-bottom: 15px;
    }
    @media screen and (min-width: 992px) {
      font-size: 18px;
      margin: 0;
      width: auto;
    }
  }
  .rectangle-chiclet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
    padding: 0 5px;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      gap: 10px;
      padding: 0;
      justify-content: center;
    }
  }
}

.rectangle-chiclet-item,
.rounded_rectangle-chiclet-item {
  width: 30%;
  margin-bottom: 10px;
  @media screen and (min-width: 375px) {
    margin-bottom: 15px;
    width: 31%;
  }
  @media screen and (min-width: 992px) {
    width: 12.5%;
    margin-bottom: 0;
  }
  @media screen and (min-width: 1024px) {
    width: 12.7%;
  }
  @media screen and (min-width: 1200px) {
    min-width: 163px;
  }
}

.circlewithborder-section {
  margin-bottom: 15px;
  @media only screen and (min-width: 992px) {
    margin-bottom: 30px;
  }
  h2 {
    text-align: center;
    margin: 0 20px 20px 0;
    line-height: 29px;
    @media screen and (min-width: 768px) {
      margin: 20px 40px 40px;
    }
  }
  .circlewithborder-chiclet {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    @media only screen and (min-width: 1450px) {
      gap: 70px;
    }
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      text-align: center;
      gap: 0;
    }
    > div {
      @media only screen and (max-width: 767px) {
        width: 33.3%;
        margin: 0 auto;
      }
    }
  }
}

.circle-section {
  margin-bottom: 15px;
  @media only screen and (min-width: 992px) {
    margin-bottom: 30px;
  }
  h2 {
    text-align: center;
    margin: 0 20px 20px 0;
    line-height: 29px;
    @media screen and (min-width: 768px) {
      margin: 20px 40px 40px;
    }
  }
  .circle-chiclet {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      text-align: center;
      gap: 0;
    }
    > div {
      @media only screen and (max-width: 767px) {
        width: 33.3%;
        margin: 0 auto;
      }
    }
  }
}

.large_rectangle-section {
  margin-bottom: 15px;
  @media only screen and (min-width: 992px) {
    margin-bottom: 30px;
  }
  h2 {
    text-align: left;
    line-height: 29px;
    margin: 0 15px 20px;
    @include breakpoint('medium') {
      margin: 20px 40px 40px;
      text-align: center;
    }
  }

  .large_rectangle-chiclet {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      flex-wrap: nowrap;
    }
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      text-align: center;
    }
    > div {
      width: 50%;
      padding: 0 8px 15px 15px;
      min-height: 90px;
      @include breakpoint('medium') {
        width: 31.3333%;
        padding: 0 0 15px;
        margin: 0 1%;
        min-height: 99px;
      }
      @include breakpoint('xlarge') {
        width: 14.666%;
        padding: 0;
        min-height: 85px;
      }
      &:nth-child(even) {
        padding: 0 15px 15px 7px;
        @include breakpoint('medium') {
          padding: 0 0 15px;
        }
        @include breakpoint('xlarge') {
          padding: 0;
        }
      }
    }
  }
}

.image-section {
  margin-bottom: 15px;
  @media only screen and (min-width: 992px) {
    margin-bottom: 30px;
  }
  h2 {
    text-align: center;
    margin: 0 20px 20px 0;
    line-height: 29px;
    @media screen and (min-width: 768px) {
      margin: 20px 40px 40px;
    }
  }
  .image-chiclet {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      text-align: center;
      gap: 0;
    }
    .image-chiclet-item {
      width: 98px;
      margin: 0 10px 10px;
      font-weight: typography.$bold;
      letter-spacing: -0.025em;
      position: relative;
      @media only screen and (min-width: 992px) {
        width: 102px;
        margin: 0 20px 20px;
        letter-spacing: -0.015em;
      }
      @include breakpoint('mlarge') {
        width: 140px;
      }
      @include breakpoint('xxlarge') {
        width: 190px;
      }
    }
  }
}

.gift-section {
  margin-bottom: 15px;
  @media only screen and (min-width: 992px) {
    margin-bottom: 30px;
  }
  h2 {
    text-align: center;
    margin: 0 20px 20px 0;
    line-height: 29px;
    @media screen and (min-width: 768px) {
      margin: 20px 40px 40px;
    }
  }
  .gift-chiclet {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      text-align: center;
      gap: 0;
    }
    .gift-chiclet-item {
      height: 116px;
      width: 98px;
      margin: 10px;
      font-weight: typography.$bold;
      letter-spacing: -0.025em;
      position: relative;
      @media only screen and (min-width: 992px) {
        height: 224px;
        width: 190px;
        margin: 20px;
        letter-spacing: -0.015em;
      }
    }
  }
}

.default-chiclet-container-section {
  margin-bottom: 15px;
  @media only screen and (min-width: 992px) {
    margin-bottom: 30px;
  }
  h2 {
    text-align: center;
    margin: 0 20px 20px 0;
    line-height: 29px;
    @media screen and (min-width: 768px) {
      margin: 20px 40px 40px;
    }
  }

  .default-chiclet-container-chiclet {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 30px 15px 0 15px;
    flex-wrap: wrap;
    @media only screen and (min-width: 992px) {
      align-items: flex-start;
      gap: 10px;
      margin: 0;
      flex-wrap: nowrap;
    }
    .default-chiclet-container-chiclet-item {
      display: flex;
      width: 102px;
      align-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      text-align: center;
      @media only screen and (min-width: 992px) {
        width: 140px;
      }
      @media only screen and (min-width: 1200px) {
        width: 180px;
      }
    }
  }
}
