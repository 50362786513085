
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.plp-container {
  padding: 0 15px;
  margin: 20px auto 0;
  display: block;
  @include breakpoint('large') {
    margin-top: 0px;
  }
}

.plp-container-box {
  display: flex;
}

.product-guided-nav {
  display: none;
  @include breakpoint('large') {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  > div:empty {
    display: none;
  }
}
.plp-seoData {
  font-weight: typography.$light;
}

.product-divider {
  display: none;
  @include breakpoint('large') {
    display: block;
  }
}

.assortment-layout {
  display: flex;
  flex-direction: row;
}

.assortment-list {
  margin: 0 auto;
  position: relative;
}

.store-result-list {
  padding-top: 50px;
  position: relative;
  @include breakpoint('large') {
    padding-top: 0;
    position: static;
  }
}

.assortment-plp-layout {
  margin: 0 0 25px;
}
