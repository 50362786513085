
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.mobile-menu-right {
  margin-top: 12px;
  li {
    display: flex;
    align-items: center;
    font-size: 14px;
    a {
      font-size: 12px;
      color: color.$black-color;
      padding: 0;
      display: block;
      background: 0 0;
      line-height: 18px;
      font-weight: typography.$medium;
    }
  }
}
