
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.footer-block {
  border-bottom: 1px solid #ccc;
  @media screen and (min-width: 768px) {
    border: 0;
  }
  .customer-support {
    @media screen and (min-width: 991px) {
      display: flex;
    }
  }
  &:first-child {
    @media screen and (min-width: 768px) {
      width: 68%;
      padding-left: 15px;
      padding-right: 0;
    }
    @media screen and (min-width: 992px) {
      padding-left: 9px;
      width: 59.6%;
    }
  }
  &:last-child {
    @media screen and (min-width: 768px) {
      width: 30%;
      padding: 0 15px;
      border-left: 1px solid #ccc;
    }
    @media screen and (min-width: 992px) {
      width: 40.4%;
      padding-left: 30px;
      padding-bottom: 0;
      margin-bottom: 16px;
    }
  }
  .footerHeading {
    span {
      padding: 15px;
      margin-bottom: 5px;
      position: relative;
      display: block;
      cursor: pointer;
      &::after,
      &::before {
        content: '';
        background: #000;
        display: block;
        position: absolute;
        height: 2px;
        transition: transform 0.35s;
        right: 15px;
        top: 28px;
        width: 16px;
        left: auto;
      }
    }

    color: color.$black-color;
    font-weight: typography.$bold;
    font-size: 22px;
    line-height: 22px;
    @media screen and (max-width: 767px) {
      margin-bottom: 0;
    }
  }
  .heading-md {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 5px;
    padding: 0 0 6px;
    color: color.$black-color;
    font-weight: typography.$bold;
    letter-spacing: -0.015em;
  }
}
