
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.image {
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
}

.image-wrapper {
  margin-top: 20px;
  border-bottom: 1px solid color.$light-gray;
  padding: 10px 0px;
  @include breakpoint('large') {
    margin-top: 0px;
    border-bottom: none;
  }
}
