
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
// Variations of background and Color
.white {
  color: color.$white-color;
}

.image-bottom-text {
  color: color.$gray2;
}

.text-color {
  color: var(--chicletTextColor);
}

.chiclet-link {
  background-color: var(--chickletColor);
}

.chiclet-link:hover {
  background-color: var(--chickletHoverColor);
}

.chiclet-link-border {
  background-color: var(--chickletColor);
  outline: 6px double var(--chickletColor);
  border-radius: var(--chicletBorderRadius);
  font-size: var(--chicletFontSize);
}

.chiclet-link-border:hover {
  background-color: var(--chickletHoverColor);
  outline: 6px double var(--chickletHoverColor);
}

// Start styling

.rounded_rectangle {
  height: 33px;
  flex-shrink: 0;
  text-align: center;
  color: color.$white-color;
  border-radius: 6.034px;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
  @media screen and (min-width: 992px) {
    height: auto;
  }
  a {
    color: color.$white-color;
    font-weight: typography.$black;
    font-size: 12px;
    line-height: 33px;
    display: block;
    border-radius: 33px;
    @media screen and (min-width: 992px) {
      font-size: 17px;
      line-height: normal;
      padding: 14px 0;
    }
    .chiclet-sub-text {
      margin-left: 4px;
      font-weight: typography.$bold;
    }
  }
}

.circle {
  border-radius: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 0 auto 20px;
  width: 102px;
  height: 102px;

  @media only screen and (min-width: 992px) {
    width: 140px;
    height: 140px;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 1200px) {
    width: 180px;
    height: 180px;
  }

  .age-limit {
    font-weight: typography.$bold;
    font-size: 22px;
    line-height: 28px;
    @media only screen and (min-width: 992px) {
      font-size: 40px;
      line-height: 46px;
    }
  }

  .month-year {
    font-size: 14px;
    line-height: 18px;
    @media only screen and (min-width: 992px) {
      font-size: 22px;
      line-height: 24px;
    }
  }

  .underline {
    height: 1.75px;
    width: 22px;
    background-color: color.$white-color;
    margin: 15px auto 0 auto;
    @media only screen and (min-width: 1200px) {
      height: 3px;
      width: 40px;
      line-height: 24px;
      margin: 20px auto 0 auto;
    }
  }
}

.circlewithborder {
  a {
    color: color.$white-color;
  }
}

.rectangle {
  height: 33px;
  flex-shrink: 0;
  text-align: center;
  color: color.$white-color;
  border-radius: 6.034px;
  @media screen and (min-width: 992px) {
    height: 52px;
  }
  a {
    color: color.$white-color;
    font-weight: typography.$black;
    font-size: 12px;
    line-height: 33px;
    @media screen and (min-width: 992px) {
      font-size: 16px;
      line-height: 52px;
    }
    &:hover {
      text-decoration: underline;
    }
    .chiclet-text {
      font-size: var(--chicletMobileFontSize);
      font-weight: typography.$black;
      @include breakpoint('large') {
        font-size: var(--chicletTabletFontSize);
      }
      @include breakpoint('xlarge') {
        font-size: 18px;
        font-weight: typography.$black;
      }
    }
    .chiclet-sub-text {
      margin-left: 4px;
      font-weight: typography.$bold;
    }
  }
}

.no-decoration {
  &:hover {
    transform: scale(1.03, 1.03);
  }
  a:hover {
    text-decoration: none;
  }
}

.large-rectangle {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: color.$white-color;
    font-weight: typography.$bold;
    font-size: 12px;
    line-height: 33px;
    line-height: normal;
    padding: 10px 5px;
    @include breakpoint('medium') {
      padding: 15px 5px;
    }
    @media screen and (min-width: 992px) {
      font-size: 16px;
      line-height: normal;
    }
    .chiclet-text {
      font-weight: typography.$bold;
      line-height: calc(19px + 0.2vw);
      @media screen and (min-width: 390px) {
        line-height: 21px;
        letter-spacing: -0.015em;
      }
    }
  }
}

.image-item {
  .subTitle {
    position: absolute;
    top: 52%;
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: color.$white-color;
    font-size: 12px;
    font-weight: typography.$bold;
    letter-spacing: -0.025em;
    transform: translateY(-40%);
    line-height: 14px;
    z-index: 1;

    @media only screen and (min-width: 992px) {
      font-size: 20px;
      letter-spacing: -0.015em;
      line-height: 21px;
      padding: 0 10px;
    }

    .chiclet-text {
      font-size: 22px;
      line-height: 28px;
      display: block;
      @media only screen and (min-width: 992px) {
        font-size: 40px;
        line-height: 46px;
      }
    }

    .chiclet-sub-title {
      font-size: 12px;
      font-weight: typography.$bold;
      letter-spacing: -0.025em;
      line-height: 14px;
      @media only screen and (min-width: 992px) {
        font-size: 20px;
        letter-spacing: -0.015em;
        line-height: 21px;
      }
    }
  }

  img {
    max-width: 100%;
  }

  .image-bottom-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: typography.$bold;
    text-decoration: none;
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    color: color.$blue;
    &:hover {
      text-decoration: underline;
    }

    @media only screen and (min-width: 992px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.gift {
  a {
    position: absolute;
    top: 52%;
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: color.$white-color;
    font-size: 12px;
    font-weight: typography.$bold;
    letter-spacing: -0.025em;
    transform: translateY(-40%);
    line-height: 14px;

    @media only screen and (min-width: 992px) {
      font-size: 20px;
      letter-spacing: -0.015em;
      line-height: 21px;
    }
  }

  img {
    max-width: 100%;
  }
}

.default-item {
  display: block;
  img {
    width: 100%;
    margin-bottom: 15px;
  }

  .default-item-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: typography.$bold;
    text-decoration: none;
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
    @media only screen and (min-width: 992px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
