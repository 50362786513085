
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.mobileHeader {
  display: flex;
  align-items: center;
  padding: 11px 0 0 0px;
  @media screen and (max-width: 767px) {
    padding: 11px 0 0 3px;
  }
  @media screen and (min-width: 992px) {
    display: none;
  }
  .bars-icon {
    .trigger {
      display: inline-block;
      font-size: 30px;
      span {
        display: block;
        margin-bottom: 5px;
        background-color: color.$white-color;
        border-radius: 2px;
        width: 30px;
        height: 4px;
        @media screen and (max-width: 767px) {
          width: 24px;
          height: 3px;
        }
      }
    }
  }
  .location-icon {
    padding-left: 15px;
    display: inline-flex;
    align-items: center;
    margin-top: -6px;
    @media screen and (min-width: 376px) {
      padding-left: 21px;
    }
    .store-icon {
      width: 20px;
      height: 20px;
      background: variables.$background-lakeshore-sprites -80px 0 / 200px 200px;
      display: inline-block;
      @media screen and (min-width: 768px) {
        width: 24px;
        height: 24px;
        background: variables.$background-lakeshore-sprites -96px 0 / 240px
          240px;
      }
    }
    .close {
      display: none;
    }
  }
  .closeIcon {
    width: 16px;
    height: 16px;
    background: variables.$background-lakeshore-sprites -112px -16px / 160px
      160px;
    display: inline-block;
  }
}

.mobileMenuLogo {
  .mobileHeader {
    position: absolute;
    right: 0;
    top: 0;
  }
}
