
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.page-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.7;
  z-index: 9999;

  .spinner-icon {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff url(../../../../public/assets/images/spinner/spinner.gif)
      center center no-repeat;
    z-index: 9999;
  }
}

.spinner {
  top: 112px;
  height: calc(-112px + 100vh);
}
