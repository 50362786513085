
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.close-icon {
  position: absolute;
  top: 20px;
  right: 13px;
  width: 16px;
  height: 16px;
  background: variables.$background-lakeshore-sprites -48px -48px / 160px 160px;
  cursor: pointer;
}

.stay-connected-wrap {
  width: 100%;
  padding: 10px;
  position: fixed;
  color: color.$white-color;
  background-color: color.$light-blue2;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  z-index: 99;
  letter-spacing: -0.015em;
  bottom: -180px;
  transition: bottom 1.5s ease-in-out;
  align-items: center;
  &.show {
    bottom: 0;
  }
  p {
    margin-bottom: 5px;
    font-size: 12px;
    a {
      color: color.$white-color;
      text-decoration: underline;
    }
  }
  form {
    display: flex;
    align-items: flex-end;
    gap: 13px;
    position: relative;
    top: -3px;
  }
}

.stay-connected {
  display: flex;
  gap: 15px;
  align-items: flex-end;
  margin-left: -40px;
  strong {
    font-size: 18px;
    font-weight: typography.$bold;
  }
}

.stay-left-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  p {
    margin-bottom: 0;
  }
}

.stay-connected-form-fields {
  input {
    font-size: 14px;
    color: color.$gray;
    height: 38px;
    font-weight: typography.$light;
  }
  ul {
    margin: 0;
    padding-top: 8px;
    border-radius: 4px;
    bottom: 0;
    border: 1px solid color.$gray5;
    width: 100%;
    li {
      &:last-child {
        position: relative;
        &::before {
          display: inline-block;
          margin-left: 0.255em;
          vertical-align: 0.255em;
          content: '';
          border-bottom: 0.3em solid color.$black-color;
          border-right: 0.3em solid transparent;
          border-left: 0.3em solid transparent;
          position: absolute;
          right: 16px;
          top: 22px;
        }
      }
      a {
        padding: 10px 6px 10px 11px;
        font-size: 13px;
        color: color.$black-color;
        font-weight: typography.$light;
        @media all and (max-width: 1000px) {
          padding: 8px 5px 8px 5px;
          font-size: 12px;
        }
      }
    }
  }
  .buttonList {
    color: color.$black-color;
    background-color: color.$white-color;
    padding: 0 30px 0 11px;
    text-align: left;
    height: 38px;
    font-size: 13.5px;
    letter-spacing: -0.015em;
    line-height: 17px;
    width: 100%;
    position: relative;
    border: 1px solid color.$gray5;
    &::placeholder {
      color: color.$black-color;
      opacity: 1;
    }
    &.userShopBottomerror {
      border-color: color.$red;
    }
    @media all and (max-width: 1000px) {
      font-size: 12px;
    }
    &:hover {
      background-color: color.$white-color;
      color: color.$black-color;
      border-color: color.$white-color;
    }
  }
}

.select-user-shop {
  width: 208px;
  &::after {
    position: absolute;
    right: 15px;
    bottom: 17px;
    content: '';
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid color.$black-color;
  }
}

.stay-connected-button {
  height: 38px;
  background-color: color.$blue4;
  border-color: color.$blue4;
  width: 114px;
  font-weight: typography.$bold;
  @media all and (max-width: 1360px) {
    font-size: 12px;
    padding: 0;
  }
  &:hover {
    background-color: color.$blue4;
    border-color: color.$blue4;
  }
}

.form-field-error {
  display: flex;
}

.glyphicon-exclamation-sign {
  &::before {
    content: '\e101';
  }
}

.buttonList {
  background: color.$white-color;
}

.stay-connected-form-fields {
  &.email {
    width: 210px;
  }
  &.zipcode {
    width: 100px;
  }
  position: relative;
  ul {
    transform: translate(0px, 0px) !important;
  }
}

.error-msg {
  font-size: 13px;
  margin: 0 0 6px 4px;
  line-height: 16px;
}

.icon {
  background: url(../../../../public/assets/images/exclamation-sign.webp)
    no-repeat center;
  height: 20px;
  width: 26px;
}

@media all and (min-width: 767px) and (max-width: 1200px) {
  .stay-connected {
    flex-direction: column;
    align-items: center;
  }
  .stay-left-section {
    gap: 10px;
    flex-direction: row;
    align-items: baseline;
  }
}

.Inputerror {
  input {
    border-color: color.$red;
  }
}

@media all and (max-width: 767px) {
  .stay-connected-wrap.show {
    bottom: -250px;
  }
}

.success-message {
  position: fixed;
  bottom: -180px;
  width: 100%;
  text-align: center;
  color: color.$white-color;
  background-color: color.$light-blue2;
  font-size: 14px;
  padding: 21px 10px;
  transition: bottom 1.5s ease-in-out;
  &.show {
    bottom: 0;
  }
}
