
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 105px;
  font-size: 18px;
  text-align: center;

  @media (max-width: 768px) {
    gap: 20px;
  }
}

.icon-container a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none !important;
  color: #181818;
  margin-left: 5px;
  cursor: pointer;
}

.icon-container i {
  font-size: 40px;

  @media (max-width: 768px) {
    font-size: 30px;
  }
}

.icon-container span {
  font-size: 18px;
  font-weight: 350;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.icon-container a:hover {
  color: #181818;
}

.icn-go-back {
  font-style: normal;

  &::before {
    content: '\e97a';
    font-size: 52px;
    color: #181818;
    font-weight: 400;

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }
}

.icn-refresh {
  font-style: normal;

  &::before {
    content: '\e97c';
    font-size: 52px;
    color: #181818;
    font-weight: 400;

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }
}

.icn-home-solid {
  font-style: normal;

  &::before {
    content: '\e97b';
    font-size: 52px;
    color: #181818;
    font-weight: 400;

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }
}

.category-box {
  padding: 20px;
  color: white;
  font-weight: bold;
  height: 66px;
  width: 182px;
  font-size: 17px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
}

.vertical-align {
  top: 0 !important;
}

@media (max-width: 992px) {
  .category-box {
    width: calc(50% - 10px);
  }
}

@media (max-width: 576px) {
  .category-box {
    width: 100%;
    height: auto;
    padding: 15px;
    font-size: 16px;
  }
}

.pineGreen {
  background: #068452;
}

.darkPurple {
  background: #c31edc;
}

.darkPink {
  background: #e60a57;
}

.blueNavyNew {
  background: #4745ab;
}

.blueLagoon {
  background: #03758f;
}

.darkOrange {
  background: #d64000;
}
