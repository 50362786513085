
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.lakeshore-tab {
  font-size: 12px;
  line-height: 14px;
  color: color.$white-color;
  background-color: color.$blue-black;
  display: inline-block;
  text-decoration: none;
  font-weight: typography.$medium;
  @media (min-width: 786px) {
    padding: 9px 14px;
  }
  @media (min-width: 992px) {
    padding: 12px 13px;
  }
  min-height: 32px;
}
.furniture-tab {
  @media (min-width: 992px) {
    padding-left: 15px;
    padding-right: 14px;
  }
  &:hover {
    background-color: color.$black-color-hover;
  }
}

.active {
  background-color: color.$blue;
}

.ll-xs-eyebrow {
  display: none;
}

.webTab {
  @media (max-width: 786px) {
    display: flex;
    .lakeshore-tab {
      flex: 1;
      text-align: center;
      padding: 9px 15px;
    }
  }
}

.headerTab-wrap {
  width: 100%;
  @include breakpoint('medium') {
    width: auto;
  }
}
.eyebrowSkeleton {
  margin-top: -1px;
  display: block;
  @include breakpoint('medium') {
    margin-top: -5px;
    display: block;
  }
}
