
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.review-count {
  margin-left: 6px;
  display: inline-block;
}

.review-count-con-based {
  font-size: 12px;
}
