
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.add-to-cart-button {
  margin-top: 15px;
  background-color: color.$red;
  color: color.$white-color;
  border: none;
  padding: 12px 20px;
  font-size: 12px;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  &:hover {
    background-color: color.$danger-btn-hover-color;
    border-color: color.$danger-btn-hover-color;
  }
  @include breakpoint('medium') {
    width: auto;
    padding: 9px 20px;
    font-size: 14px;
    line-height: 20px;
  }
}
.add-to-cart-wrap {
  z-index: 1;
  background-color: color.$white-color;
  width: 100%;
  @include breakpoint('medium') {
    width: auto;
  }
  .add-to-cart-button {
    &:hover {
      background-color: color.$danger-btn-hover-color;
      border-color: color.$danger-btn-hover-color;
    }
  }
  &.fixed {
    position: fixed;
    z-index: 5;
    bottom: 0;
    border-top: solid 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.3);
    height: 80px;
    width: 100%;
    padding: 0 15px;
    left: 0;
    .add-to-cart-button {
      padding: 14px 20px;
      width: 100%;
    }
    @include breakpoint('medium') {
      position: static;
      border-top: none;
      box-shadow: none;
      height: auto;
      padding: 0;
      z-index: 1;
      .add-to-cart-button {
        width: auto;
        padding: 9px 28px;
      }
    }
  }
}

.icn-check-hvy {
  color: color.$white-color;
  margin-right: 5px;
  &::before {
    content: '\e911';
  }
}

.fade-out {
  opacity: 0.25;
}
