
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.location-detail {
  > div {
    margin-bottom: 10px;
  }
  h4 {
    font-weight: 700;
    line-height: 22px;
    color: color.$black-color;
    margin-bottom: 5px;
    font-size: 15px;
  }
  .view-all-store-details a {
    color: color.$primary-color;
    text-decoration: none;
  }
}

.btnLink {
  color: color.$primary-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.select-modal-phone-wrap {
  margin-bottom: 10px;
}
