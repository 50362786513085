
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.add-to-registry-wrap {
  position: relative;
  cursor: pointer;
}
.add-to-registry {
  display: inline-block;
  margin-right: 18px;
  padding-left: 25px;
  position: relative;
  font-size: 12px;
  color: #020202;
  &:hover {
    text-decoration: underline;
  }
  &::before {
    content: '';
    display: block;
    left: 1px;
    width: 16px;
    height: 16px;
    background: variables.$background-lakeshore-sprites -16px -16px / 160px;
    position: absolute;
    top: 2px;
    left: 3px;
  }
}
