
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.requestMoreInformation {
  .dialogModal {
    width: 100%;
    margin: 20px auto;
    max-width: 550px;
    width: 92%;
    @include breakpoint('medium') {
      margin-top: 70px;
    }
    .modalContent {
      .modalTitle {
        font-size: 24px;
      }
      .subTitle {
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 15px;
        margin-top: 5px;
      }
      .dailogBtn {
        display: block;
        margin-bottom: 15px;
        width: 100%;
        @include breakpoint('medium') {
          display: inline-block;
          margin-bottom: 0;
          width: auto;
        }
      }
      .inputField {
        input {
          font-size: 16px;
          font-weight: typography.$light;
          @include breakpoint('medium') {
            font-size: 0.875rem;
          }
        }
      }
      .textareaField {
        textarea {
          font-size: 16px;
          font-weight: typography.$light;
          @include breakpoint('medium') {
            font-size: 0.875rem;
          }
        }
      }
    }
  }
  .error-msg {
    color: color.$red;
    font-size: 14px;
    width: 100%;
    margin: 0;
    padding: 0;
    font-weight: typography.$medium;
  }
}

.input-wrapper {
  &.has-error {
    textarea,
    input {
      margin-bottom: 0px;
    }
  }
  textarea,
  input {
    margin-bottom: 13px;
  }
}
.input-wrapper {
  margin-bottom: 13px;
}

.fields-required {
  font-weight: typography.$bold;
  font-size: 13px;
}

.contactForm-closeBtn {
  text-align: center;
  margin-top: 40px;
}
