
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.pageContent-banner {
  padding: 0;
}

.home-carousel {
  margin-bottom: 30px;
  .previous-prev-icon {
    opacity: 1;
    width: 70px;
    @media screen and (max-width: 767px) {
      width: 30px;
    }
    .carousel-control-prev-icon {
      background-image: url(../../../../../public/assets/images/arrow-lg-left-ds.svg);
      background-color: transparent;
      background-size: auto;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      height: 100px;
      width: 33px;
      @media screen and (max-width: 767px) {
        height: 30px;
        width: 10px;
      }
    }
  }
  .previous-next-icon {
    opacity: 1;
    width: 70px;
    @media screen and (max-width: 767px) {
      width: 30px;
    }
    .carousel-control-next-icon {
      background-image: url(../../../../../public/assets/images/arrow-lg-right-ds.svg);
      background-color: transparent;
      background-size: auto;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      height: 100px;
      width: 33px;
      @media screen and (max-width: 767px) {
        height: 30px;
        width: 10px;
      }
    }
  }
  .carousel-indicator-main {
    margin-bottom: -30px;
    .indicator {
      width: 11px;
      height: 11px;
      background-color: color.$light-gray;
      margin: 5px 5px;
      border: 2px solid color.$white-color;
      border-radius: 100%;
      opacity: 1;
    }
    .play-pause-btn {
      margin: 0 5px;
      padding: 0;
      background: transparent;
      border: 0;
    }
  }
}

.heroImage {
  margin-bottom: 20px;
}
.gsa-heroImage {
  margin: 20px auto;
}
