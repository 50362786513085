
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.buttonWrapper {
  display: block;
  width: 100%;
  text-align: center;

  @media screen and (min-width: 768px) {
    display: inline-block;
    width: auto;
  }

  .takeItOutButton {
    display: inline-block;
    font-weight: typography.$medium;
    border-radius: 33px;
    width: auto;
    padding: 7px 26px;
    font-size: 16px;
    line-height: 20px;
    background-color: var(--mobileFlavorColor);
    border-color: var(--mobileFlavorColor);
    color: var(--textMobileFlavorColor);

    @include breakpoint('large') {
      background-color: var(--webFlavorColor);
      border-color: var(--webFlavorColor);
      color: var(--textWebFlavorColor);
    }

    @media screen and (min-width: 1200px) {
      padding: 9px 38px;
      font-size: 18px;
      line-height: 26px;
    }
  }

  .takeItOutThreeButton {
    display: block;
    width: 90%;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
      width: 100%;
    }

    @media screen and (min-width: 992px) {
      min-width: 320px;
      display: inline-block;
      width: auto;
    }
  }
}
