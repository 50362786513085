
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.add-to-list-link {
  display: inline-block;
  margin-right: 18px;
  padding-left: 22px;
  position: relative;
  font-size: 12px;
  color: #020202;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &::before {
    content: '';
    display: block;
    left: 1px;
    width: 16px;
    height: 16px;
    background: variables.$background-lakeshore-sprites -32px -64px / 160px
      160px;
    position: absolute;
    top: 2px;
    left: 3px;
  }
}
